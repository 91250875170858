export const BLACK = 0;
export const WHITE = 1;

export const PAWN = 0;
export const ROOK = 1;
export const KNIGHT = 2;
export const BISHOP = 3;
export const QUEEN = 4;
export const KING = 5;

export const EMPTY = -1;

export class Piece {
  constructor(color, type) {
    this.color = color;
    this.type = type;
    this.passantable = false;
    this.moves = 0;
  }

  setType(type) {
    this.type = type;
  }

  getInfoText() {
    if(this.moves === 1) {
      return "Has made 1 move"
    } else {
      return "Has made " + this.moves + " moves"
    }
  }

  isEmpty() {
    return this.type === EMPTY;
  }

  isFull() {
    return !this.isEmpty();
  }

  isBlack() {
    return this.color === BLACK;
  }

  isWhite() {
    return this.color === WHITE;
  }

  isEnemyOf(piece) {
    if (this.color === EMPTY || piece.color === EMPTY) {
      return false;
    } else {
      return this.color !== piece.color;
    }
  }

  isFriendOf(piece) {
    if (this.color === EMPTY || piece.color === EMPTY) {
      return false;
    } else {
      return this.color === piece.color;
    }
  }

  is(type) {
    return this.type === type;
  }

  hasMoved() {
    return this.moves !== 0;
  }

  hasNotMoved() {
    return !this.hasMoved();
  }
}

export const startingBoard = () => {
  let squares = [];
  const mainRow = [ROOK, KNIGHT, BISHOP, QUEEN, KING, BISHOP, KNIGHT, ROOK];
  function add(num, color, type) {
    for(let i = 0; i < num; i++) {
      squares.push(new Piece(color, type));
    }
  }

  mainRow.forEach(type => add(1, WHITE, type));
  add(8, WHITE, PAWN);
  add(32, EMPTY, EMPTY);
  add(8, BLACK, PAWN);
  mainRow.forEach(type => add(1, BLACK, type));

  return ({
    squares,
    blackIsNext: true,
    hand: {
      heldPiece: null,
    },
    showPopup: false,
    settings: {},
  });
}

export const textFromBoard = ({ state }) => {
  const turn = state?.blackIsNext ? 'B' : 'W';
  return turn + state?.squares.map(square => {
    if (!square) {
      return '_';
    }
    let color = (c) => {
      return square.color === BLACK ? c.toUpperCase() : c;
    };
    switch (square.type) {
      case ROOK:
        return color('r');
      case KNIGHT:
        return color('n');
      case BISHOP:
        return color('b');
      case QUEEN:
        return color('q');
      case KING:
        return color('k');
      case PAWN:
        return color('p');
      default:
        return '_';
    }
  }).join('');
}
