import React from 'react';
import ReactDOM from 'react-dom';
import io from 'socket.io-client';

import Board from './components/Board.js';

import './index.css';

const ENDPOINT = process.env.REACT_APP_QUICKCHESS_API || "https://quickchess-api.sagev.space";

class NameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: ''};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSubmit = props.onSubmit;
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    this.onSubmit(this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} style={{ marginBottom: '15em', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <label htmlFor='lobby-name'>
          <h1 className='white-on-light' style={{ fontSize: '5rem', textAlign: 'center' }}>Lobby Name:</h1>
        </label>
        <div style={{ textAlign: 'center', width: '80%' }}>
          <input className='big-input' style={{ width: '50%' }} name='lobby-name' type="text" value={this.state.value} onChange={this.handleChange} />
          <input className='big-input' style={{ marginLeft: '8px' }} type="submit" value="Join" />
        </div>
      </form>
    );
  }
}

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      board: null,
      socket: io(ENDPOINT),
      gameKey: null,
    };

    this.state.socket.on("FromAPI", data => {
      console.log(data);
      this.setState({
        board: null,
      });
      this.setState({
        board: (
          <Board
           text={data}
           lobbyName={this.state.gameKey}
           onMove={(move) => {
             console.log("ON MOVE");
             this.state.socket.emit("move", this.state.gameKey, move);
          }}/>
        ),
      });
    });
  }

  setGameKey(key) {
    console.log(`Setting gameKey to '${key}'`);
    this.setState({gameKey: key});
    this.state.socket.emit("find", key);
  }

  render() {
    return (
      <>
        <h2 className="white-on-light">QuickChess</h2>
        <div className="game">
          <div className="game-board">
            {this.state.gameKey ? this.state.board : <NameForm onSubmit={this.setGameKey.bind(this)} />}
          </div>
        </div>
      </>
    );
  }
}

// ========================================

ReactDOM.render(
  <Game />,
  document.getElementById('root')
);
