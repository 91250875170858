import React from 'react';
import './style.css';

class Popup extends React.Component {
  render() {
    return (
      <div className='popup'>
        <div className='popup-outer'
          onClick={this.props.closePopup}>
        </div>
        <div className='popup-inner'>
          <h1>{this.props.header}</h1>
          {this.props.body}
        </div>
      </div>
    );
  }
}


export default Popup;
